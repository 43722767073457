/** @format */

.ag-theme-quartz {
    --ag-foreground-color: #7e2e84 !important;
    --ag-background-color: rgb(249, 245, 227) !important;
    --ag-header-foreground-color: rgb(204, 245, 172) !important;
    --ag-header-background-color: #2e2586 !important;
    --ag-odd-row-background-color: rgba(219, 3, 3, 0.03) !important;
    /* --ag-header-column-resize-handle-color: rgb(228, 18, 243) !important; */
    --ag-font-size: 17px !important;
    --ag-font-family: monospace !important;
    --ag-border-radius: 15px !important;
    --ag-wrapper-border-radius: 15px !important;
}

.ag-theme-quartz .ag-header {
    height: 30px !important;
}