/** @format */
/* body {
  background-color: #ececec;
} */

/* Swiper container */
.mySwiper {
  position: relative;
}

/* Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* 
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: rgba(255, 255, 255, 1);
} */
:root {
  scrollbar-color: rgb(47 37 135) rgb(255 255 255) !important;
  scrollbar-width: thin !important;
}