/** @format */

.full-screen-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  width: 44.8px;
  height: 44.8px;
  color: #554cb5;
  position: relative;
  background: radial-gradient(11.2px, currentColor 94%, #5d3bf100);
}

.loader:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: radial-gradient(
        10.08px at bottom right,
        #9f65e700 94%,
        currentColor
      )
      top left,
    radial-gradient(10.08px at bottom left, #9f65e700 94%, currentColor) top
      right,
    radial-gradient(10.08px at top right, #9f65e700 94%, currentColor) bottom
      left,
    radial-gradient(10.08px at top left, #9f65e700 94%, currentColor) bottom
      right;
  background-size: 22.4px 22.4px;
  background-repeat: no-repeat;
  animation: loader 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes loader {
  33% {
    inset: -11.2px;
    transform: rotate(0deg);
  }

  66% {
    inset: -11.2px;
    transform: rotate(90deg);
  }

  100% {
    inset: 0;
    transform: rotate(90deg);
  }
}
