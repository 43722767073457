/** @format */

.ag-theme-quartz {
  --ag-foreground-color: #7e2e84 !important;
  --ag-background-color: rgb(249, 245, 227) !important;
  --ag-header-foreground-color: rgb(204, 245, 172) !important;
  --ag-header-background-color: #2e2586 !important;
  --ag-odd-row-background-color: rgba(219, 3, 3, 0.03) !important;
  /* --ag-header-column-resize-handle-color: rgb(228, 18, 243) !important; */
  --ag-font-size: 17px !important;
  --ag-font-family: monospace !important;
  --ag-border-radius: 15px !important;
  --ag-wrapper-border-radius: 15px !important;
}
.ag-theme-quartz .ag-header {
  height: 30px !important;
}

.ag-header-cell-menu-button {
  opacity: 1 !important;
}

.ag-header-cell-menu-button:hover {
  opacity: 1 !important;
}

.ag-header-cell-menu-button .ag-icon {
  display: block !important;
  color:rgb(204, 245, 172) !important;
}

.ag-header-cell-label .custom-filter-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.ag-icon-filter {
  display: inline-block;
  width: 16px; 
  height: 16px;
  fill: currentColor; 
  vertical-align: middle;
}

.ag-icon-filter:hover {
  fill: #007bff;
}



.ag-theme-quartz ::-webkit-scrollbar {
  width: 10px !important; 
  height: 10px !important; 
}

.ag-theme-quartz ::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; 
  border-radius: 5px; 
}

.ag-theme-quartz ::-webkit-scrollbar-thumb:hover {
  background-color: #afafaf; 
}


.ag-body-vertical-scroll-viewport{
  width: 10px !important; 
}


